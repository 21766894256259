import { makeStyles } from 'tss-react/mui'
import { CustomAppSpinnerType } from './AppSpinner'

const useStyles = makeStyles<CustomAppSpinnerType>({
    name: 'AppSpinner',
})((theme, params) => {
    return {
        root: {},

        colorSecondary: {
            color: theme.palette.common.white,
        },
    }
})

export default useStyles
