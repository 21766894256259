import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'

export const makeStore = () =>
    configureStore({
        reducer: rootReducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: false,
                serializableCheck: false,
            }),
    })

export type AppStore = ReturnType<typeof makeStore>

export type AppState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
