import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CommonState } from './types'
import { commonApi } from '@/utils/api/common'
import { commonReducerAction } from './action'
import { AppState } from '..'

const initialState: CommonState = {
    contacts: null,
}

export const getContactsThunk = createAsyncThunk('common/getContactsThunk', async () => {
    const { data: response } = await commonApi.getContacts()

    return response
})

const commonStore = createSlice({
    name: 'auth',
    initialState,
    reducers: commonReducerAction,
    extraReducers(builder) {
        builder.addCase(getContactsThunk.fulfilled, (state, { payload }) => {
            state.contacts = payload.params
        })
    },
})

export const selectContacts = (state: AppState) => state.common.contacts

export const commonReducer = commonStore.reducer
