import { AxiosResponseData } from '@/libs/axios'
import { PRODUCT_REQUEST_ERROR_TRANSLATE_KEY } from '@/utils/constants/product.constant'
import { TFunction } from 'i18next'

export const shortenSwitchName = (switchName: string | undefined) => {
    if (!switchName) return ''
    const regex = /(?:Gateron G Pro|Cherry MX) (.*)/
    const match = regex.exec(switchName)
    if (match) return match[1]
}

export const shortenProductName = (productName: string | undefined) => {
    if (!productName) return ''
    const result = productName.split(' ')

    return result[result.length - 1]
}

export const getErrorMessageProduct = (
    t: TFunction<'translation', undefined>,
    error: AxiosResponseData,
    productName: string
): string => {
    const [errorKey, productId] = error.message.split(':')

    return PRODUCT_REQUEST_ERROR_TRANSLATE_KEY[errorKey]
        ? t(PRODUCT_REQUEST_ERROR_TRANSLATE_KEY[errorKey], { ns: 'product', value: productName })
        : t('someErrorOccur', { ns: 'common' })
}
