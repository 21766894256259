import { RawAxiosRequestHeaders } from 'axios'

export enum ProductSortResourceApiRoute {
    SERIES_LIST_ROUTE = '/series/getListNameByFilter.json',

    SWITCHES_LIST_ROUTE = '/dfswitches/getListName.json',
}

export type SeriesResponseType = {
    id: SafeNumber
    name: string
}

export type SwitchesResponseType = {
    id: SafeNumber
    name: string
}
