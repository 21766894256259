import { SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'
import { AuthStateType } from './types'
import { jwtService } from '@/services/jwt'

export const authReducerAction: ValidateSliceCaseReducers<AuthStateType, SliceCaseReducers<AuthStateType>> = {
    signOut: (state) => {
        jwtService.destroyToken()
        return { ...state, user: null }
    },
}
