import { Backdrop } from '@mui/material'
import useStyles from './LoadingScreenOverlap.styles'

import AppImage from '../AppImage'
import { jwtService } from '@/services/jwt'
import { useAppSelector } from '@/hooks'
import { selectFetchUserStatus } from '@/store/auth/reducer'
import { selectFetchProductSortResourceStatus } from '@/store/productSortResource/reducer'
import { useMemo } from 'react'
import { selectFetchCartStatus } from '@/store/cart/reducer'

const LoadingScreenOverlap = () => {
    const authToken = jwtService.getToken()

    const $s_authUserFetchStatus = useAppSelector(selectFetchUserStatus)
    const $s_userCartFetchStatus = useAppSelector(selectFetchCartStatus)
    const $s_productResourceFetchStatus = useAppSelector(selectFetchProductSortResourceStatus)

    const isShowLoading = useMemo(() => {
        if (authToken) return $s_authUserFetchStatus && $s_userCartFetchStatus && $s_productResourceFetchStatus

        return $s_productResourceFetchStatus
    }, [authToken, $s_authUserFetchStatus, $s_productResourceFetchStatus])

    const { classes } = useStyles()

    return (
        <Backdrop open={isShowLoading} className={classes.root}>
            <div className={classes.content}>
                <AppImage className={classes.logo} fill objectFit="cover" maxWidth={100} priority src="/img/logo.svg" />

                <div className={classes.loaderWrap}>
                    <div className="loader" />
                </div>
            </div>
        </Backdrop>
    )
}

export default LoadingScreenOverlap
