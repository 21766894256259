import axios from 'axios'
import nextI18NextConfig from '@@/next-i18next.config'

import { commonConfig } from '@/utils/configs'
import { jwtService } from '@/services/jwt'
import { commonHelpers } from '@/utils/helpers'

const authAxios = axios.create({
    baseURL: commonConfig.API_BASE_URL,
})

authAxios.interceptors.request.use(
    (req) => {
        const token = jwtService.getToken()

        if (!req.headers['Authorization']) req.headers['Authorization'] = `Bearer ${token}`
        if (!req.headers['Language']) {
            const lang = typeof window !== 'undefined' ? window.NextPublic.lang.replace('-', '_') : nextI18NextConfig.i18n!.defaultLocale.replace('-', '_')
            req.headers['Language'] = lang
        }

        switch ((req.method as string).toUpperCase()) {
            case 'GET': {
                req.params = req.params || {}

                break
            }
            case 'POST': {
                if (!(req.data instanceof FormData) && !!req.data) {
                    req.data = commonHelpers.formatFormData(req.data)
                }

                if (req.data instanceof FormData) {
                } else {
                    req.data = req.data || {}
                }
                break
            }
            case 'PUT': {
                req.data = req.data || {}

                break
            }
            case 'DELETE': {
                req.data = req.data || {}

                break
            }
        }
        return req
    },
    (err) => {
        console.log(err)
        return Promise.reject(err)
    }
)

authAxios.interceptors.response.use(
    (res) => {
        return res
    },
    (err) => {
        // return Promise.reject(err)
        return err.response
    }
)

export default authAxios
