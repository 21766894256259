import { combineReducers } from '@reduxjs/toolkit'
import { authReducer } from './auth/reducer'
import { productSortResourceReducer } from './productSortResource/reducer'
import { cartReducer } from './cart/reducer'
import { commonReducer } from './common/reducer'

const rootReducer = combineReducers({
    auth: authReducer,

    productSortResource: productSortResourceReducer,
    cart: cartReducer,

    // common
    common: commonReducer,
})

export default rootReducer
