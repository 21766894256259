export const CONTACT_INFO = {
    phone: '(+84) 767 529 866',
    mail: 'sales@apt-biztech.com',
    address_vn: '523 Tô Hiến Thành, Phường 14, Quận 10, Tp. Hồ Chí Minh, Việt Nam',
    address_en: '523 To Hien Thanh Street, Ward 14, District 10, City. Ho Chi Minh, Vietnam',
    facbook_url: process.env.NEXT_PUBLIC_FACEBOOK_PAGE_URL,
    ig_url: process.env.NEXT_PUBLIC_INSTAGRAM_PAGE_URL,
    zalo_url: process.env.NEXT_PUBLIC_ZALO_PAGE_URL,
}

export const GENDER = [
    { label: 'female', value: 0 },
    { label: 'male', value: 1 },
    { label: 'other', value: 2 },
] as const

export const ACCEPTED_FILE_TYPE = ['image/jpg', 'image/png'] as const

export const MAX_FILE_SIZE = 500 * 1024 // 500kb

export const CALENDAR_VIEW_FORMAT = 'DD - MM - YYYY' as const
export const CALENDAR_BACKEND_FORMAT = 'YYYY-MM-DD' as const

export const SECTION_CONTACT_HTML_ID = 'dfire-contact'
