import '@/styles/globals.scss'

import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import React, { ReactElement, ReactNode, useEffect } from 'react'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { TssCacheProvider } from 'tss-react'
import createEmotionCache from '@/libs/createEmotionCacheMui/createEmotionCacheMui'
import { type UseTranslationResponse } from 'react-i18next'
import createTssEmotionCache from '@/libs/createEmotionCacheApp/createEmotionCacheApp'
import { useTranslation } from 'next-i18next'
import { appWithTranslation } from 'next-i18next'
import nextI18NextConfig from '@@/next-i18next.config'
import { commonConfig } from '@/utils/configs'
import { DefaultSeo } from 'next-seo'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { CssBaseline, NoSsr, ThemeProvider } from '@mui/material'
import theme from '@/assets/theme/theme.default'
import localFont from 'next/font/local'
import AppToastify from '@/components/AppToastify'
import StoreProvider from '@/store/StoreProvider'
import { toast } from 'react-toastify'
import { jwtService } from '@/services/jwt'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { fetchUser, selectAuthUser } from '@/store/auth/reducer'
import { useRouter } from 'next/router'
import { getTempCartThunk, selectCart } from '@/store/cart/reducer'
import RouterLoadingLinearProgress from '@/components/RouterLoadingLinearProgress'
import { getProductSortResources, selectSeriesList, selectSwitchesList } from '@/store/productSortResource/reducer'
import ScrollTopButton from '@/components/ScrollTopButton'
import CookieAccessRequest from '@/components/CookieAccessRequest'
import LoadingScreenOverlap from '@/components/LoadingScreenOverlap'
import FacebookChatFloatButton from '@/containers/FacebookChatFloatButton'
import { FacebookProvider } from 'react-facebook'
import { FACEBOOK_APP_ID } from '@/utils/configs/common.config'
import { getContactsThunk, selectContacts } from '@/store/common/reducer'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (
        page: ReactElement,
        pageProps: P,
        appProps: {
            translation: UseTranslationResponse<'common', any>
        }
    ) => ReactNode
}

interface MyAppProps extends AppProps {
    Component: NextPageWithLayout
    emotionCache?: EmotionCache
    tssEmotionCache?: EmotionCache
}

const UnauthenticatedPaths = ['/sign-in', '/register', '/forgot-password']

const appFont = localFont({
    src: [
        {
            path: '../../public/fonts/SVN-GilroyThin-100.otf',
            weight: '100',
        },
        {
            path: '../../public/fonts/SVN-GilroyXlight-200.otf',
            weight: '200',
        },
        {
            path: '../../public/fonts/SVN-GilroyLight-300.otf',
            weight: '300',
        },
        {
            path: '../../public/fonts/SVN-GilroyRegular.otf',
            weight: '400',
        },
        {
            path: '../../public/fonts/SVN-GilroySemiBold-450.otf',
            weight: '450',
        },
        {
            path: '../../public/fonts/SVN-GilroyBold-600.otf',
            weight: '600',
        },
    ],
})

const clientSideEmotionCache = createEmotionCache()
const tssClientSideEmotionCache = createTssEmotionCache()

const InitialMyAppEffect = () => {
    const checkCookie = () => {
        const cookieEnabled = navigator?.cookieEnabled
        if (!cookieEnabled) {
            toast.warning(
                'Your browser has cookies disabled. Make sure your cookies are enabled to best use Dragon-Fire'
            )
        }
    }

    useEffect(() => {
        checkCookie()
    }, [])

    return null
}

const FetchInitData = () => {
    const dispatch = useAppDispatch()

    const token = jwtService.getToken()

    const $s_authUser = useAppSelector(selectAuthUser),
        $s_cart = useAppSelector(selectCart),
        $s_switcheList = useAppSelector(selectSwitchesList),
        $s_serieList = useAppSelector(selectSeriesList),
        $s_contacts = useAppSelector(selectContacts)

    useEffect(() => {
        if (!$s_serieList || !$s_switcheList) dispatch(getProductSortResources())
        if (!$s_contacts) dispatch(getContactsThunk())

        if (token) {
            if (!$s_authUser) dispatch(fetchUser())
            if (!$s_cart) dispatch(getTempCartThunk())
        }
    }, [])

    return null
}

const AuthRouteGuard = ({ children }: { children: React.ReactNode }) => {
    const hasAuthToken = !!jwtService.getToken()
    const router = useRouter()

    useEffect(() => {
        if (hasAuthToken && UnauthenticatedPaths.includes(router.pathname)) router.push(router.asPath)
    }, [])

    return <>{children}</>
}

const MyApp: React.FunctionComponent<MyAppProps> = (props) => {
    const {
        Component,
        emotionCache = clientSideEmotionCache,
        tssEmotionCache = tssClientSideEmotionCache,
        pageProps: { session, ...pageProps },
    } = props

    const headerLocale =
        ((pageProps?._nextI18Next?.initialLocale || '').replace('-', '_') as string) ||
        nextI18NextConfig.i18n!.defaultLocale.replace('-', '_')

    const getLayout = Component.getLayout ?? ((page) => page)
    const translation = useTranslation()

    return (
        <>
            <DefaultSeo
                defaultTitle={commonConfig.DOCUMENT_TITLE}
                description={commonConfig.DOCUMENT_DESCRIPTION}
                titleTemplate={`%s | ${commonConfig.DOCUMENT_TITLE}`}
                openGraph={{
                    type: 'website',
                    locale: headerLocale,
                    title: commonConfig.DOCUMENT_TITLE,
                    description: commonConfig.DOCUMENT_DESCRIPTION,
                    siteName: commonConfig.DOCUMENT_TITLE,
                }}
            />
            <style jsx global>{`
                html {
                    font-family: ${appFont.style.fontFamily};
                }
            `}</style>
            <StoreProvider>
                <GoogleOAuthProvider clientId={commonConfig.GOOGLE_CLIENT_ID}>
                    <CacheProvider value={emotionCache}>
                        <TssCacheProvider value={tssEmotionCache}>
                            <ThemeProvider theme={theme}>
                                <FacebookProvider appId={FACEBOOK_APP_ID!} version="v18.0">
                                    <AuthRouteGuard>
                                        <LoadingScreenOverlap />

                                        <main className={appFont.className}>
                                            <NoSsr>
                                                {/* Fetch essential APIs */}
                                                <FetchInitData />
                                                {/* UI Component */}
                                                <AppToastify />
                                                <ScrollTopButton />
                                                <CookieAccessRequest />

                                                <RouterLoadingLinearProgress />

                                                {/* Facebook chat */}
                                                <FacebookChatFloatButton />
                                            </NoSsr>

                                            <InitialMyAppEffect />

                                            <CssBaseline />

                                            {getLayout(<Component {...pageProps} />, pageProps, {
                                                translation,
                                            })}
                                        </main>
                                    </AuthRouteGuard>
                                </FacebookProvider>
                            </ThemeProvider>
                        </TssCacheProvider>
                    </CacheProvider>
                </GoogleOAuthProvider>
            </StoreProvider>
        </>
    )
}

export default appWithTranslation(MyApp, nextI18NextConfig as any)
