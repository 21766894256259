import authAxios from '@/libs/axios/auth.axios'
import {
    CartRoute,
    CheckValidCouponPayload,
    CheckValidCouponResponse,
    CheckoutCartPayload,
    CreateTempCartPayload,
    TempCartResponse,
} from './cart.api.types'
import { AxiosResponseData } from '@/libs/axios'

export const tempCartApi = {
    getTempCart: () => {
        return authAxios.get<AxiosResponseData<TempCartResponse[]>>(CartRoute.GET_TEMP_CART_ROUTE)
    },
    createTempCart: (payload: CreateTempCartPayload) => {
        return authAxios.post<AxiosResponseData>(CartRoute.CREATE_TEMP_CART_ROUTE, payload.params)
    },
    updateTempCart: (payload: CreateTempCartPayload) => {
        return authAxios.post<AxiosResponseData>(CartRoute.CREATE_TEMP_CART_ROUTE, payload.params)
    },

    checkValidCoupon: (payload: CheckValidCouponPayload) => {
        return authAxios.get<AxiosResponseData<CheckValidCouponResponse>>(CartRoute.CHECK_VALID_COUPON_ROUTE, {
            ...payload,
        })
    },

    checkoutCart: (payload: CheckoutCartPayload) => {
        return authAxios.post<AxiosResponseData>(CartRoute.CHECKOUT_CART_ROUTE, payload.params)
    },
}
