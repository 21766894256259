import { Icon, IconProps } from "@mui/material"
import { forwardRef } from "react"
import useStyles from "./AppSvgIcon.styles"
import { AppThemeColor } from "@/types/app"

export type CustomIconType = {
	color?: "primary" | "secondary" | "error" | "inherit" | AppThemeColor
}

export type AppSvgIconType = Omit<IconProps, keyof CustomIconType> & CustomIconType

const AppSvgIcon = forwardRef((props: AppSvgIconType, ref: React.ForwardedRef<any>) => {
	const {
		color = "primary",
		fontSize = "small",
		className,
		classes: muiClasses,
		sx,
		...rest
	} = props

	const { classes, theme, cx, css } = useStyles({ color })

	const appSvgIconClasses = cx(classes.root, className)

	return (
		<Icon
			ref={ref}
			fontSize={fontSize}
			className={appSvgIconClasses}
			{...rest}
			classes={{
				...muiClasses,
				root: cx(classes.root, sx && css(theme.unstable_sx(sx) as any)),
				fontSizeSmall: cx(classes.fontSizeSmall),
			}}
		/>
	)
})

export default AppSvgIcon
