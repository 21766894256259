import { makeStyles } from 'tss-react/mui'
import { CustomIconType } from './AppSvgIcon'
import _get from 'lodash/get'

const useStyles = makeStyles<CustomIconType>({
    name: 'AppSvgIcon',
})((theme, params) => {
    let color
    switch (params.color) {
        case 'primary': {
            color = theme.palette.primary.main
            break
        }
        case 'secondary': {
            color = theme.palette.secondary.main
            break
        }
        case 'error': {
            color = theme.palette.error.main
            break
        }
        default:
            color = _get(theme.palette, params?.color ?? '') ?? (params?.color || 'transparent')
            break
    }

    return {
        root: {
            fill: 'currentColor',
            color,
        },

        fontSizeSmall: {
            fontSize: 16,
        },
    }
})

export default useStyles
