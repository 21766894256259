import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({
    name: 'CookieAccessRequest',
})((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.common.white,

            zIndex: 15,
            position: 'fixed',
            left: 16,
            bottom: 16,

            borderRadius: 0,
            width: '100%',
            maxWidth: 320,
        },
    }
})

export default useStyles
