import router, { NextRouter } from 'next/router'

export const isMobile = () => {
    return (
        typeof window !== 'undefined' &&
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    )
}

export const isIOS = () => {
    return (
        typeof window !== 'undefined' &&
        (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
            navigator.userAgent.includes('Mac'))
    )
}

export const isEmpty = (val: any) => {
    return ['', null, undefined].includes(val) || (Array.isArray(val) && val.length === 0)
}

export const isNumber = (number: any) => {
    return !isEmpty(number) && !isNaN(Number(number))
}

export const formatFormData = (data: Object) => {
    const fd = new FormData()
    Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value) && value.some((v) => typeof window !== 'undefined' && v instanceof File)) {
            fd.append(`${key}[]`, value as any)
        } else {
            fd.append(
                key,
                typeof value === 'string' || (typeof window !== 'undefined' && value instanceof File)
                    ? value
                    : JSON.stringify(value)
            )
        }
    })
    return fd
}

export const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const isExpired = (expired_date: Date) => {
    let now = new Date()
    return now > expired_date
}

export const onChangeQueryString = (objectQuery: NextRouter['query']) => {
    const [keyQuery, valueQuery] = Object.entries(objectQuery)[0]

    let resultQuery = { ...router.query, ...objectQuery }
    if (!valueQuery && router.query[keyQuery]) delete resultQuery[keyQuery]

    return router.replace(
        {
            query: resultQuery,
        },
        undefined,
        {
            shallow: true,
            scroll: false,
        }
    )
}

export const convertToNumber = (inputString: string): number => {
    const regex = /^[-+]?[0-9]*\.?[0-9]+(?:,[0-9]{3})*(?:\.[0-9]{2})?$/

    if (regex.test(inputString)) {
        // Convert the valid number string to a float or integer
        const number = parseFloat(inputString.replace(/,/g, ''))
        return number
    } else {
        console.error('Common Helpers log ==>', `The input string '${inputString}' is not a valid number format.`)
        return 0
    }
}

export const parseStyles = (stringStyles: string | React.CSSProperties) =>
    typeof stringStyles === 'string'
        ? stringStyles.split(';').reduce((acc, style) => {
              const colonPosition = style.indexOf(':')

              if (colonPosition === -1) {
                  return acc
              }

              const camelCaseProperty = style
                      .substr(0, colonPosition)
                      .trim()
                      .replace(/^-ms-/, 'ms-')
                      .replace(/-./g, (c) => c.substr(1).toUpperCase()),
                  value = style.substr(colonPosition + 1).trim()

              return value ? { ...acc, [camelCaseProperty]: value } : acc
          }, {})
        : {}

export const parseFileSize = (bytes: File['size'], decimals = 2) => {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const windowScrollTop = () => {
    if (typeof window !== 'undefined')
        return window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
}

export const formatCurrency = (number?: number | string, options?: Intl.NumberFormatOptions) => {
    if (!isNumber(number)) return number
    // const locale = window.NextPublic.lang

    // const vnPrefix: Intl.NumberFormatOptions = {
    //     style: 'currency',
    //     currency: 'VND',
    // }
    // const usPrefix: Intl.NumberFormatOptions = {
    //     style: 'currency',
    //     currency: 'USD',
    // }

    // const prefixOpt = locale.includes('vi') ? vnPrefix : usPrefix

    return new Intl.NumberFormat('jp', {
        // ...prefixOpt,
        ...options,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(Number(number))
}

export const encodeBase64 = (str: string) =>
    typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str)

export const isUrl = (url: string) => {
    try {
        return Boolean(new URL(url))
    } catch (e) {
        return false
    }
}
