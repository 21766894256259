import { PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'
import { CartState, TransformTempCart } from './types'
import { CreateTempCart, TempCartResponse } from '@/utils/api/cart'

export const cartReducerAction: ValidateSliceCaseReducers<CartState, SliceCaseReducers<CartState>> = {
    changeStatusLoadingCartItem: (state, { payload }: PayloadAction<TransformTempCart>) => {
        if (!state.cart || !state.cart.length) return { ...state }

        const cloneCart = [...state.cart]
        const cartIdToEdit = payload.id
        const index = cloneCart.findIndex((item) => item.id === cartIdToEdit)
        cloneCart[index] = { ...cloneCart[index], loading: !cloneCart[index].loading }

        return { ...state, cart: cloneCart }
    },
    changeStatusEditCartItem: (state, { payload }: PayloadAction<TransformTempCart>) => {
        if (!state.cart || !state.cart.length) return { ...state }

        const cartIdToEdit = payload.id
        return state.cart.forEach((item) => (item.isEdit = item.id === cartIdToEdit))
    },
    cancelAllEditCartItem: (state) => {
        if (!state.cart || !state.cart.length) return { ...state }

        return state.cart.forEach((item) => (item.isEdit = false))
    },
    removeCart: (state) => {
        return { ...state, cart: null, totalCash: 0, totalItems: 0 }
    },

    updateCartItemQuantity: (state, { payload }: PayloadAction<CreateTempCart>) => {
        if (!state.cart || !state.cart.length) return { ...state }

        const updatedCart = state.cart.map((item) => {
            if (item.product_detail_id === payload.product_detail_id) return { ...item, quantity: payload.quantity }

            return item
        })

        return { ...state, cart: updatedCart }
    },

    updateCartProductSelected: (state, { payload }) => {
        return { ...state, cartProductSelected: [...payload] }
    },

    updateCartCheckout: (state, { payload }: PayloadAction<TempCartResponse[]>) => {
        if (!payload || !payload.length) return { ...state, cartCheckout: null }

        return { ...state, cartCheckout: [...payload] }
    },
}
