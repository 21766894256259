import { forwardRef } from 'react'

import { ButtonProps, Button } from '@mui/material'
import useStyles from './AppButton.styles'
import AppSpinner from '../AppSpinner'

export type CustomAppButtonPropsType = {
    isDark?: boolean
    loadingState?: boolean
}

export type AppButtonProps = Omit<ButtonProps, keyof CustomAppButtonPropsType> & CustomAppButtonPropsType

const AppButton = forwardRef((props: AppButtonProps, ref: React.ForwardedRef<any>) => {
    const { isDark, disabled, className, classes: muiClasses, sx, loadingState, children, onClick, ...rest } = props

    const { classes, theme, cx, css } = useStyles({ isDark })

    const appButtonClasses = cx(classes.root, className)

    return (
        <Button
            ref={ref}
            disableElevation
            {...rest}
            className={appButtonClasses}
            classes={{
                ...muiClasses,
                root: cx(classes.root, css(sx && (theme.unstable_sx(sx) as any))),
                // Variant
                outlinedPrimary: cx(classes.outlinedPrimary),
                outlinedSecondary: cx(classes.outlinedSecondary),
                containedPrimary: cx(classes.containedPrimary),
                containedSecondary: cx(classes.containedSecondary),
                textInfo: cx(classes.textInfo),
                // Size
                sizeSmall: cx(classes.sizeSmall),
                sizeMedium: cx(classes.sizeMedium),
                sizeLarge: cx(classes.sizeLarge),
                // Icon
                startIcon: cx(classes.startIcon),
                endIcon: cx(classes.endIcon),
            }}
            // Icon
            disabled={disabled || loadingState}
            onClick={onClick}
        >
            {loadingState ? <AppSpinner size="small" /> : children}
        </Button>
    )
})

export default AppButton
