import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({
    name: 'ScrollTopButton',
})(() => {
    return {
        stickyButton: {
            zIndex: 15,
            position: 'fixed',

            bottom: 96,
            right: 32,

            transition: '.2s',
        },
    }
})

export default useStyles
