export const DOCUMENT_TITLE = 'Dragon Fire'
export const DOCUMENT_DESCRIPTION = ''
export const APP_VERSION = '0.0.1'
export const APP_NAME = 'dragon_fire'

export const API_BASE_URL = process.env.NEXT_PUBLIC_API_PORT
    ? `${process.env.NEXT_PUBLIC_API_HOST}:${process.env.NEXT_PUBLIC_API_PORT}/${process.env.NEXT_PUBLIC_API_PREFIX}`
    : `${process.env.NEXT_PUBLIC_API_HOST}/${process.env.NEXT_PUBLIC_API_PREFIX}`

export const DEFAULT_GMT = '+07:00'
export const DEFAULT_PHONE_COUNTRY_CODE = '+84'

export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID
export const GOOGLE_CLIENT_SECRET = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_SECRET

export const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID
export const FACEBOOK_CHAT_PAGE_ID = process.env.NEXT_PUBLIC_FACEBOOK_CHAT_PAGE_ID

export const CDN_DOMAIN = process.env.NEXT_PUBLIC_CDN_DOMAIN
export const CDN_HOST = process.env.NEXT_PUBLIC_CDN_HOST
