import { RawAxiosRequestHeaders } from 'axios'
import { ProductImage } from '../products'
import { DeliveryMethod } from '../common'
import { CreateUserAddressPayload, UserAddressResponse } from '../auth'

export enum CartRoute {
    CREATE_TEMP_CART_ROUTE = '/memberTempCarts/createMemberTempCart.json',
    GET_TEMP_CART_ROUTE = '/memberTempCarts/getList.json',

    CHECK_VALID_COUPON_ROUTE = '/memberCarts/calculateMemberCart.json',

    CHECKOUT_CART_ROUTE = '/memberCarts/createMemberCart.json',
}

type Payload = {
    headers?: RawAxiosRequestHeaders
}

export type CreateTempCartPayload = {
    params: {
        carts: string
    }
} & Payload

export type CheckValidCouponPayload = {
    params: {
        code: string
        total: number
        shipping_method_id: DeliveryMethod['id']
    }
} & Payload

export type CheckoutCartPayload = {
    params: {
        carts: CreateTempCart[]
        shipping_method_id: DeliveryMethod['id']
    } & CreateUserAddressPayload['params']
} & Payload

/**
 * Responses type
 */
export type TempCartResponse = {
    id: SafeNumber
    product_detail_id: SafeNumber

    inventory: number
    quantity: number
    price: number

    product_id: SafeNumber
    product_name: string
    color_name: string

    switch: {
        id: SafeNumber
        name: string
    }
    seri: {
        id: SafeNumber
        name: string
    }
    layout: {
        id: SafeNumber
        name: string
    }
    product_images: ProductImage[]
}

export type CreateTempCart = {
    product_detail_id: SafeNumber
    quantity: number
}

export type CheckValidCouponResponse = {
    total: number
    shipping_fee: number
    discount_amount: number
}
