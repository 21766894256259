import * as yup from 'yup'
import { ACCEPTED_FILE_TYPE, MAX_FILE_SIZE } from '../constants/common.constant'
import { parseFileSize } from '../helpers/common'

yup.setLocale({
    mixed: {
        required: ({ path }) => ({ label: path, key: 'error.required' }),
    },
    string: {
        email: ({ path }) => ({ label: path, key: 'error.invalid' }),
        min: ({ min, path }) => ({ label: path, key: 'error.minLength', values: { min } }),
        max: ({ max, path }) => ({ label: path, key: 'error.maxLength', values: { max } }),
    },
})

// Custom methods
yup.addMethod<yup.StringSchema>(yup.string, 'vietnamPhoneNumber', function vietnamPhoneNumber() {
    const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g

    return this.matches(regexPhoneNumber, {
        message: { label: 'phone_number', key: 'error.invalid' },
    })
})

yup.addMethod<yup.StringSchema>(yup.string, 'customValidateEmail', function customValidateEmail() {
    return this.test('custom-email', { label: 'email', key: 'error.invalid' }, function (value) {
        if (!value) return undefined

        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        const invalidPrefixDomainPattern = /^\.|\.\.|\.$/

        return pattern.test(value) && !invalidPrefixDomainPattern.test(value)
    })
})

yup.addMethod<yup.StringSchema>(yup.string, 'emailOrPhoneNumber', function emailOrPhoneNumber() {
    const validateEmail = (email: string | undefined) => yup.string().email().customValidateEmail().isValidSync(email)
    const validateVietnamPhoneNumber = (phoneNumber: string | undefined) =>
        yup.string().vietnamPhoneNumber().isValidSync(phoneNumber)

    return this.test(
        'emailOrPhoneNumber',
        { label: 'username', key: 'error.invalid' },
        (value, ctx) => validateEmail(value) || validateVietnamPhoneNumber(value)
    )
})

yup.addMethod<yup.MixedSchema>(yup.mixed, 'fileValidate', function fileValidate() {
    const acceptFileType = ACCEPTED_FILE_TYPE
    const maxFileSize = MAX_FILE_SIZE
    const fileSizeString = parseFileSize(maxFileSize)
    const fileTypeString = acceptFileType.join(', ').replaceAll('image/', '.')

    return this.test({
        name: 'fileValidate',
        message: ({ temp, path }) => {
            if (temp === 'fileSize') return { label: path, key: 'error.fileSize', values: { size: fileSizeString } }
            if (temp === 'fileType') return { label: path, key: 'error.fileType', values: { type: fileTypeString } }
        },
        params: { temp: true },
        exclusive: true,
        test: function (value: any) {
            if (!value) return true

            const validSize = value.size <= maxFileSize
            const validType = acceptFileType.includes(value.type)

            if (!validSize) return this.createError({ params: { temp: 'fileSize' } })

            if (!validType) return this.createError({ params: { temp: 'fileType' } })

            return validSize && validType
        },
    })
})

export default yup
