import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({
    name: 'LoadingScreenOverlap',
})((theme) => {
    return {
        root: {
            backgroundColor: 'rgba(0,0,0, 0.1)',
            backdropFilter: 'blur(6px)',
            zIndex: theme.zIndex.backdrop + 1,
            overflowY: 'hidden',
        },

        logo: {
            zIndex: theme.zIndex.backdrop,
        },

        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

            width: '5vw',
            minWidth: 50,
        },

        loaderWrap: {
            transform: 'translateY(-50%)',
        },
    }
})

export default useStyles
