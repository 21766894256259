import { SupportTypeValue } from '@/utils/constants/support.constant'
import { RawAxiosRequestHeaders } from 'axios'

export enum CommonRoute {
    // User subcribe to receive markt email
    USER_SUBCRIBE_ROUTE = 'newsLetters/createNewsLetter.json',
    USER_FEEDBACK_ROUTE = 'feedbacks/createFeedback.json',

    // Privacy - Q&A
    SUPPORT_PRIVACY_ROUTE = 'texts/getTextByType.json',
    FREQUENTLY_ASKED_QUESTIONS_ROUTE = 'answers/getListByFilter.json',

    // Video banner
    VIDEO_BANNER_ROUTE = 'banners/getList.json',

    // About us content
    ABOUT_US_ROUTE = 'abouts/getList.json',
    CONTACTS_ROUTE = 'contacts/getContact.json',
    STAFFS_ROUTE = 'staffs/getList.json',

    //
    CITIES_ROUTE = 'cities/getList.json',
    DISTRICTS_ROUTE = 'districts/getListByCity.json',
    WARDS_ROUTE = 'wards/getListByDistrict.json',

    DELIVERY_METHOD_ROUTE = 'shippingMethods/getList.json',
    PAYMENT_METHOD_ROUTER = 'paymentMethods/getList.json',
}

type Payload = {
    headers?: RawAxiosRequestHeaders
}

export type UserSubcribePayload = {
    params: {
        name: string
        email: string
    }
} & Payload

export type UserFeedbackPayload = {
    params: {
        name: string
        email: string
        message: string
    }
} & Payload

export type SupportPrivacyPayload = {
    params: {
        type: SupportTypeValue
    }
} & Payload

export type FrequentlyAskedQuestionsPayload = {
    params: {
        search?: string
    }
} & Payload

export type DistrictsPayload = {
    params: {
        city_id: City['id']
    }
} & Payload

export type WardsPayload = {
    params: {
        district_id: District['id']
    }
} & Payload

/**
 * Response types
 */
export type SupportPrivacyResponse = {
    type: SupportTypeValue
    name: string
    description: string
}

export type FrequentlyAskedQuestionsResponse = {
    total: number
    items: FrequentlyAskedQuestions[]
}

export type VideoBannerResponse = {
    id: SafeNumber
    path: string
}

export type ContactsResponse = {
    facebook_url: string
    zalo_url: string
    instagram_url: string
    phone: string
    email: string
    address: string
}

export type AboutUsResponse = {
    id: SafeNumber
    sort: number
    path: AboutUsImage[]
    description: string
}

export type Staffs = {
    id: SafeNumber
    sort: number
    name: string
    role: string
    path: string
}

export type FrequentlyAskedQuestions = {
    id: SafeNumber
    question_name: string
    answer_name: string
}

export type City = {
    id: SafeNumber
    name: string
}

export type District = {
    id: SafeNumber
    name: string
}

export type Ward = {
    id: SafeNumber
    name: string
}

export type AboutUsImage = {
    id: SafeNumber
    type: 'Abouts-thumbnail' | 'Abouts-background'
    path: string
}

export type DeliveryMethod = {
    id: SafeNumber
    fee: number
    name: string
    description: string
}

export type PaymentMethod = {
    id: SafeNumber
    name: string
}
