import { commonAxios } from '@/libs'
import { ProductSortResourceApiRoute, SeriesResponseType, SwitchesResponseType } from './productSortResource.type.api'
import { AxiosResponseData } from '@/libs/axios'

const productSortResourceApi = {
    getSeriesList: () => {
        return commonAxios.get<AxiosResponseData<SeriesResponseType[]>>(ProductSortResourceApiRoute.SERIES_LIST_ROUTE)
    },
    getSwitchesList: () => {
        return commonAxios.get<AxiosResponseData<SwitchesResponseType[]>>(ProductSortResourceApiRoute.SWITCHES_LIST_ROUTE)
    },
}

export default productSortResourceApi
