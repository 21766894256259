import { IconButton, IconButtonProps } from '@mui/material'
import AppSvgIcon from '../AppSvgIcon'
import { AppSvgIconType } from '../AppSvgIcon/AppSvgIcon'
import { PropsWithChildren, forwardRef } from 'react'
import useStyles from './AppIconButton.styles'
import { AppThemeColor } from '@/types/app'
import _get from 'lodash/get'

export type StylesIconButtonProps = {
    variant?: 'outlined' | 'filled' | 'contained'
    color?: 'primary' | 'secondary' | 'error' | 'inherit' | AppThemeColor
    hoverColor?: AppThemeColor
    loading?: boolean
}

export type AppIconButtonProps = {
    icon?: AppSvgIconType['component']
} & PropsWithChildren &
    StylesIconButtonProps &
    Omit<IconButtonProps, keyof StylesIconButtonProps>

const AppIconButton = forwardRef((props: AppIconButtonProps, ref: React.ForwardedRef<any>) => {
    const {
        className,
        sx,
        classes: muiClasses,
        color = 'primary',
        variant = 'outlined',
        hoverColor,
        icon,
        ...rest
    } = props

    const { classes, cx, css, theme } = useStyles({ variant, color, hoverColor })

    return (
        <IconButton
            ref={ref}
            classes={{
                ...muiClasses,
                root: cx(classes.root, classes.variant, sx && css(theme.unstable_sx(sx) as any), className),
                sizeMedium: cx(classes.sizeMedium),
            }}
            {...rest}
        >
            {icon && <AppSvgIcon component={icon} color={props.disabled ? 'common.borderDisable' : color} />}
        </IconButton>
    )
})

export default AppIconButton
