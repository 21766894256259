import productSortResourceApi from '@/utils/api/productSortResource/productSortResource.api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { productSortResourceReducerAction } from './action'
import { ProductSortResourceStateType } from './types'
import { AppState } from '..'

export const getProductSortResources = createAsyncThunk('productSortResource/getProductSortResources', async () => {
    const { data: seriesResponse } = await productSortResourceApi.getSeriesList()
    const { data: switchesResponse } = await productSortResourceApi.getSwitchesList()

    return { seriesResponse, switchesResponse }
})

const initialState: ProductSortResourceStateType = {
    seriesList: null,
    switchesList: null,

    productSortResourceFetching: false,
}

const productSortResource = createSlice({
    name: 'productSortResource',
    initialState,
    reducers: productSortResourceReducerAction,
    extraReducers(builder) {
        builder.addCase(getProductSortResources.pending, (state) => {
            state.productSortResourceFetching = true
        })
        builder.addCase(getProductSortResources.fulfilled, (state, { payload }) => {
            state.seriesList = payload.seriesResponse.params
            state.switchesList = payload.switchesResponse.params

            state.productSortResourceFetching = false
        })
    },
})

export const selectFetchProductSortResourceStatus = (state: AppState) => state.productSortResource.productSortResourceFetching
export const selectSeriesList = (state: AppState) => state.productSortResource.seriesList
export const selectSwitchesList = (state: AppState) => state.productSortResource.switchesList

export const productSortResourceReducer = productSortResource.reducer
