import { Paper, Slide, Stack, Typography } from '@mui/material'
import { useTranslation } from 'next-i18next'
import useStyles from './CookieAccessRequest.styles'
import AppIconButton from '../AppIconButton'
import { Close } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import AppImage from '../AppImage'
import AppButton from '../AppButton'
import { storageService } from '@/services/storage'
import { IS_ACCEPT_COOKIES } from '@/utils/constants/storageKey.constant'

const CookieAccessRequest = () => {
    const { t } = useTranslation()

    const [show, setShow] = useState(false)

    const isAcceptCookies = storageService.getLocalItem(IS_ACCEPT_COOKIES)

    const handleClose = () => setShow(false)

    const handleAccept = () => {
        storageService.saveLocalItem(IS_ACCEPT_COOKIES, 'true')
        setShow(false)
    }

    const { classes } = useStyles()

    useEffect(() => {
        if (!isAcceptCookies || isAcceptCookies === 'false') setShow(true)
    }, [isAcceptCookies])

    return (
        <Slide direction="up" in={show} unmountOnExit>
            <Paper className={classes.root}>
                <Stack flexDirection="row" justifyContent="space-between" p={2}>
                    <Typography variant="titleSemi20">{t('cookies', { ns: 'common' })}</Typography>
                    <AppIconButton size="small" variant="filled" icon={Close} onClick={handleClose} />
                </Stack>

                <Typography px={2}>{t('cookieContent', { ns: 'common' })}</Typography>

                <Stack pt={3} pb={4} flexDirection="row" justifyContent="center">
                    <AppImage src="/img/cookie.svg" width={80} height={80} maxWidth={80} />
                </Stack>

                <Stack flexDirection="row">
                    <AppButton sx={{ flex: 1 }} variant="outlined" onClick={handleClose}>
                        {t('decline', { ns: 'button' })}
                    </AppButton>
                    <AppButton sx={{ flex: 1 }} variant="contained" onClick={handleAccept}>
                        {t('accept', { ns: 'button' })}
                    </AppButton>
                </Stack>
            </Paper>
        </Slide>
    )
}
export default CookieAccessRequest
