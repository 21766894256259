import AppIconButton from '@/components/AppIconButton'
import { ArrowUpward } from '@mui/icons-material'
import { Box, Theme, Zoom, useMediaQuery } from '@mui/material'
import useStyles from './ScrollTopButton.styles'
import { useEventListener } from '@/hooks'
import { useState } from 'react'
import { useRouter } from 'next/router'

const ScrollTopButton = () => {
    const router = useRouter()

    const isProductDetailScreen = router.pathname.startsWith('/products/') && router.query.slug
    const isDownTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('tablet'))

    const [show, setShow] = useState(false)

    const handlerWindowScroll = (e: Event) => setShow(window.scrollY >= 400)

    const handleScrollTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth',
        })
    }

    useEventListener('scroll', handlerWindowScroll)

    const { classes } = useStyles()

    if (isProductDetailScreen) return null

    return (
        <div className={classes.stickyButton}>
            <Zoom in={show}>
                <AppIconButton icon={ArrowUpward} onClick={handleScrollTop} size={isDownTablet ? 'small' : 'medium'} />
            </Zoom>
        </div>
    )
}

export default ScrollTopButton
