import { SUPPORT_MENU } from './support.constant'

export type SelectOptions = {
    value: any
    label: string
}

export const LANGUAGES_MENU: SelectOptions[] = [
    { value: 'vi-VN', label: 'VN' },
    { value: 'en-US', label: 'ENG' },
]

export const MENU = [
    {
        localeCode: 'products',
        path: '/products',
        activeAt: '/products',
        auth: false,
    },
    {
        localeCode: 'aboutUs',
        path: '/about-us',
        activeAt: '/about-us',
        auth: false,
    },
    {
        localeCode: 'support',
        path: `${SUPPORT_MENU[0].slug}`,
        activeAt: '/support',
        auth: false,
    },
    {
        localeCode: 'blogs',
        path: '/blogs',
        activeAt: '/blogs',
        auth: false,
    },
]

export const BREADCRUMBS = {
    checkout: [{ name: 'cart', path: '/cart' }],
} as { [key: string]: [{ name: string; path: string }] }
