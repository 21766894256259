import commonAxios from '@/libs/axios/common.axios'

import type { AxiosResponseData } from '@/libs/axios'
import {
    AuthApiRoute,
    CheckEmailForgetPasswordType,
    CheckEmailPayloadType,
    ForgetPasswordPayloadType,
    SignInPayloadType,
    RegisterPayloadType,
    UserResponseType,
    SignInFacebookPayloadType,
    SignInGooglePayloadType,
    SignInGoogleResponse,
    EditUserPersonalPayload,
    UserAddressResponse,
    DeleteUserAddressPayload,
    CreateUserAddressPayload,
    EditUserAddressPayload,
    UserPurchasedPayload,
    UserPurchasedResponse,
    ChangePasswordPayload,
} from './auth.api.types'
import {
    AUTH_API_CHECK_EMAIL_FORGET_PASSWORD_TYPE_ID,
    AUTH_API_CHECK_EMAIL_REGISTER_TYPE_ID,
    AUTH_API_REGISTER_TYPE_ID,
    AUTH_API_SIGN_IN_FACEBOOK_TYPE_ID,
    AUTH_API_SIGN_IN_GOOGLE_TYPE_ID,
    SEND_MAIL_CODE_CHANGE_PASSWORD,
    SEND_MAIL_CODE_EDIT_USER_PERSONAL,
} from '@/utils/constants/auth.constant'
import authAxios from '@/libs/axios/auth.axios'

const authApi = {
    signIn: (payload: SignInPayloadType) => {
        return commonAxios.post<AxiosResponseData>(AuthApiRoute.SIGN_IN_ROUTE, payload.params)
    },
    signInWithGoogle: (payload: { params: SignInGooglePayloadType['params'] }) => {
        return commonAxios.post<AxiosResponseData<SignInGoogleResponse>>(AuthApiRoute.SIGN_IN_SOCIAL_ROUTE, {
            ...payload.params,
            type: AUTH_API_SIGN_IN_GOOGLE_TYPE_ID,
        })
    },
    signInWithFacebook: (payload: { params: SignInFacebookPayloadType['params'] }) => {
        return commonAxios.post<AxiosResponseData>(AuthApiRoute.SIGN_IN_SOCIAL_ROUTE, {
            ...payload.params,
            type: AUTH_API_SIGN_IN_FACEBOOK_TYPE_ID,
        })
    },
    register: (payload: { params: Omit<RegisterPayloadType['params'], 'type'> }) => {
        return commonAxios.post<AxiosResponseData>(AuthApiRoute.SIGN_UP_ROUTE, {
            ...payload.params,
            type: AUTH_API_REGISTER_TYPE_ID,
        })
    },
    checkPhoneExist: (payload: { params: Pick<RegisterPayloadType['params'], 'phone_number'> }) => {
        return commonAxios.post<AxiosResponseData>(AuthApiRoute.CHECK_PHONE_ROUTE, payload.params)
    },
    checkEmailExist: (payload: { params: Omit<CheckEmailPayloadType['params'], 'verification_type'> }) => {
        return commonAxios.post<AxiosResponseData>(AuthApiRoute.CHECK_EMAIL_ROUTE, {
            ...payload.params,
            verification_type: AUTH_API_CHECK_EMAIL_REGISTER_TYPE_ID,
        })
    },
    checkEmailForgetPassword: (payload: {
        params: Omit<CheckEmailForgetPasswordType['params'], 'verification_type'>
    }) => {
        return commonAxios.post<AxiosResponseData>(AuthApiRoute.CHECK_EMAIL_ROUTE, {
            ...payload.params,
            verification_type: AUTH_API_CHECK_EMAIL_FORGET_PASSWORD_TYPE_ID,
        })
    },
    // Only use on forget password
    checkCodeOtpOnForgetPassword: (payload: {
        params: Omit<ForgetPasswordPayloadType['params'], 'password' | 'verification_type'>
    }) => {
        return commonAxios.post<AxiosResponseData>(AuthApiRoute.CHECK_CODE_OTP_ROUTE, {
            ...payload.params,
            verification_type: AUTH_API_CHECK_EMAIL_FORGET_PASSWORD_TYPE_ID,
        })
    },
    forgetPassword: (payload: { params: Omit<ForgetPasswordPayloadType['params'], 'verification_type'> }) => {
        return commonAxios.post<AxiosResponseData>(AuthApiRoute.FORGET_PASSWORD_ROUTE, payload.params)
    },

    /**
     * Auth user actions
     */
    fetchUser: () => {
        return authAxios.get<AxiosResponseData<UserResponseType>>(AuthApiRoute.FETCH_USER_ROUTE)
    },
    sendOtpEditPersonal: (payload: { params: { email: string } }) => {
        return commonAxios.post<AxiosResponseData>(AuthApiRoute.CHECK_EMAIL_ROUTE, {
            email: payload.params.email,
            verification_type: SEND_MAIL_CODE_EDIT_USER_PERSONAL,
        })
    },
    editUserPersonal: (payload: EditUserPersonalPayload) => {
        return authAxios.post<AxiosResponseData>(AuthApiRoute.EDIT_USER_PERSONAL_ROUTE, payload.data)
    },

    /** Password */
    checkValidCurrentPassword: (payload: { data: { password: string } }) => {
        return authAxios.post<AxiosResponseData>(AuthApiRoute.CHECK_VALID_CURRENT_PASSWORD, payload.data)
    },
    sendOtpChangePassword: (payload: { params: { email: string } }) => {
        return commonAxios.post<AxiosResponseData>(AuthApiRoute.CHECK_EMAIL_ROUTE, {
            email: payload.params.email,
            verification_type: SEND_MAIL_CODE_CHANGE_PASSWORD,
        })
    },
    changeUserPassword: (payload: ChangePasswordPayload) => {
        return authAxios.post<AxiosResponseData>(AuthApiRoute.CHANGE_PASSWORD_ROUTE, payload.data)
    },
    /**   Address   */
    getUserAddress: () => {
        return authAxios.get<AxiosResponseData<UserAddressResponse[]>>(AuthApiRoute.GET_USER_ADDRESS_ROUTE)
    },
    createUserAddress: (payload: CreateUserAddressPayload) => {
        return authAxios.post<AxiosResponseData>(AuthApiRoute.CREATE_USER_ADDRESS_ROUTE, payload.params)
    },
    editUserAddress: (payload: EditUserAddressPayload) => {
        return authAxios.put<AxiosResponseData>(AuthApiRoute.EDIT_USER_ADDRESS_ROUTE, payload.params)
    },
    setDefaultUserAddress: (payload: { params: Pick<EditUserAddressPayload['params'], 'member_address_id'> }) => {
        return authAxios.put<AxiosResponseData>(AuthApiRoute.SET_DEFAULT_USER_ADDRESS_ROUTE, payload.params)
    },
    deleteUserAddress: (payload: DeleteUserAddressPayload) => {
        return authAxios.delete<AxiosResponseData>(AuthApiRoute.DELETE_USER_ADDRESS_ROUTE, { data: payload.params })
    },

    /** Purchased */
    getUserPurchased: (payload: UserPurchasedPayload) => {
        return authAxios.get<AxiosResponseData<UserPurchasedResponse>>(AuthApiRoute.USER_PURCHASED_ROUTE, {
            params: payload.params,
            cancelToken: payload?.cancelToken,
        })
    },
}

export default authApi
