import React, { forwardRef } from 'react'
import useStyles from './AppSpinner.styles'
import { CircularProgress, CircularProgressProps } from '@mui/material'

export type CustomAppSpinnerType = {
    size?: 'small' | 'medium' | 'large' | number
}

type AppSpinnerProps = Omit<CircularProgressProps, keyof CustomAppSpinnerType> & CustomAppSpinnerType

const AppSpinner = forwardRef((props: AppSpinnerProps, ref: React.ForwardedRef<any>) => {
    const { size = 'small', classes: muiClasses, className, sx, ...rest } = props

    const { classes, css, cx, theme } = useStyles({})

    const sizeCompo = size === 'small' ? 20 : size === 'medium' ? 40 : size === 'large' ? 60 : size

    return (
        <CircularProgress
            ref={ref}
            size={sizeCompo}
            variant="indeterminate"
            color="primary"
            {...rest}
            classes={{
                ...muiClasses,
                root: cx(classes.root, sx && css(theme.unstable_sx(sx) as any), className),
                colorSecondary: classes.colorSecondary,
            }}
        />
    )
})

export default AppSpinner
