import { createTheme } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'

import appColors from './appColor'

const muiTheme = createTheme()

const fontFamily: string = 'var(--font-app), sans-serif'

const typography: TypographyOptions = {
    fontFamily: fontFamily,
    fontWeightBlack: 900,
    fontWeightExtraBold: 800,
    fontWeightBold: 700,
    fontWeightSemiBold: 600,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    sectionHeading: {
        fontFamily,
        fontWeight: 400,
        fontSize: muiTheme.typography.pxToRem(42),
        lineHeight: 'normal',
        letterSpacing: 'normal',
    },
    subSectionHeading: {
        fontFamily,
        fontWeight: 600,
        fontSize: muiTheme.typography.pxToRem(28),
        lineHeight: 'normal',
        letterSpacing: 'normal',
    },
    captionReg12: {
        fontFamily,
        fontWeight: 400,
        fontSize: muiTheme.typography.pxToRem(12),
        lineHeight: 1.19,
        letterSpacing: 'normal',
    },
    bodyReg14: {
        fontFamily,
        fontWeight: 400,
        fontSize: muiTheme.typography.pxToRem(14),
        lineHeight: 1.285,
        letterSpacing: 'normal',
    },
    bodyReg16: {
        fontFamily,
        fontWeight: 400,
        fontSize: muiTheme.typography.pxToRem(16),
        lineHeight: 1.375,
        letterSpacing: 'normal',
    },
    bodyMed14: {
        fontFamily,
        fontWeight: 500,
        fontSize: muiTheme.typography.pxToRem(14),
        lineHeight: 'normal',
        letterSpacing: 'normal',
    },
    bodyMed16: {
        fontFamily,
        fontWeight: 500,
        fontSize: muiTheme.typography.pxToRem(16),
        lineHeight: 'normal',
        letterSpacing: 'normal',
    },
    bodySemi14: {
        fontFamily,
        fontWeight: 600,
        fontSize: muiTheme.typography.pxToRem(14),
        lineHeight: 'normal',
        letterSpacing: 'normal',
    },
    bodySemi16: {
        fontFamily,
        fontWeight: 600,
        fontSize: muiTheme.typography.pxToRem(16),
        lineHeight: 1.5,
        letterSpacing: 'normal',
    },
    titleSemi20: {
        fontFamily,
        fontWeight: 600,
        fontSize: muiTheme.typography.pxToRem(20),
        lineHeight: 1.4,
        letterSpacing: 'normal',
    },
    headerSemi35: {
        fontFamily,
        fontWeight: 600,
        fontSize: muiTheme.typography.pxToRem(20),
        lineHeight: 'normal',
        letterSpacing: 'normal',
    },
    discount: {
        fontFamily,
        fontWeight: 400,
        lineHeight: 1.3333,
    },
    button: {
        fontFamily,
        fontWeight: 600,
        fontSize: muiTheme.typography.pxToRem(14),
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textTransform: 'initial',
    },
    body1: undefined,
    body2: undefined,
    caption: undefined,
    h1: undefined,
    h2: undefined,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    overline: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
}

const defaultTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            mobile: 376,
            sm: 600,
            tablet: 783,
            md: 900,
            lg: 1200,
            desktop: 1201,
            xl: 1536,
        },
        keys: ['xs', 'mobile', 'sm', 'tablet', 'md', 'lg', 'desktop', 'xl'],
    },
    palette: {
        primary: {
            main: appColors.primary!,
            contrastText: muiTheme.palette.common.white,
        },
        secondary: {
            main: appColors.secondary!,
            dark: appColors.neutralDark,
            light: appColors.neutralLight,
        },
        success: {
            main: appColors.success!,
        },
        error: {
            main: appColors.error!,
            contrastText: muiTheme.palette.common.white,
        },
        background: {
            default: appColors.neutralLightest,
        },
        divider: appColors.neutralLight,

        common: {
            ...appColors,
        },
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    zIndex: 1350,
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variant: 'bodyReg14',
                color: 'primary.main',
                variantMapping: {
                    sectionHeading: 'h3',
                    subSectionHeading: 'h5',
                    bodyReg14: 'p',
                    bodyMed14: 'p',
                    bodyMed16: 'p',
                    bodyReg16: 'p',
                    bodySemi14: 'p',
                    bodySemi16: 'p',
                    captionReg12: 'p',
                    button: 'p',
                    headerSemi35: 'p',
                    titleSemi20: 'p',
                    discount: 's',
                },
            },
        },
    },
    shape: {
        borderRadius: 12,
    },
    typography,
    zIndex: {
        backdrop: 1350,
    },
    transitions: {
        duration: {
            aos: 1000,
            switchMedium: 500,
        },
    },
})

export default defaultTheme
