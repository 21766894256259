import { makeStyles } from 'tss-react/mui'
import { StylesIconButtonProps } from './AppIconButton'
import _get from 'lodash/get'

const useStyles = makeStyles<StylesIconButtonProps>({
    name: 'AppIconButton',
})((theme, params) => {
    let color
    switch (params.color) {
        case 'primary': {
            color = theme.palette.primary.main
            break
        }
        case 'secondary': {
            color = theme.palette.secondary.main
            break
        }
        case 'error': {
            color = theme.palette.error.main
            break
        }
        default:
            color = _get(theme.palette, params?.color ?? '') ?? (params?.color || 'transparent')
            break
    }

    const outlinedButton = {
        border: `1px solid ${color}`,
    }

    const filledButton = {
        border: 'unset',
        color: 'inherit',
    }

    const containedButton = {
        backgroundColor: color,

        '> svg.MuiSvgIcon-root': {
            color: _get(theme.palette, `${params.color}.contrastText`),
        },
    }

    const disabled = {
        border: `1px solid ${theme.palette.common.borderDisable}`,
        color: 'inherit',
    }

    return {
        root: {
            borderRadius: 0,

            '&.Mui-disabled': {
                ...disabled,
            },

            '&:hover': {
                backgroundColor: _get(theme.palette, `${params.hoverColor}.main`),

                '> svg.MuiSvgIcon-root': {
                    color: _get(theme.palette, `${params.hoverColor}.contrastText`),
                },
            },
        },

        sizeMedium: {
            padding: 15.5,
        },

        variant: params.variant === 'outlined' ? outlinedButton : params.variant === 'filled' ? filledButton : containedButton,
    }
})

export default useStyles
