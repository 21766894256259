import { makeStyles } from 'tss-react/mui'

import { CustomAppButtonPropsType } from './AppButton'
import { ButtonProps } from '@mui/material'

const useStyles = makeStyles<CustomAppButtonPropsType>({
    name: 'AppInput',
    uniqId: 'nJQ02H',
})((theme) => {
    const removedDefault: React.CSSProperties = {
        borderRadius: 0,
    }

    const { fontFamily, fontWeight, fontSize, lineHeight } = theme.typography.button

    return {
        root: {
            ...removedDefault,
            fontFamily,
            fontWeight,
            fontSize,
            lineHeight,

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },

        // Sizes
        sizeSmall: {
            paddingTop: '11px',
            paddingBottom: '11px',
        },

        sizeMedium: {
            paddingTop: '14px',
            paddingBottom: '14px',
        },

        sizeLarge: {
            paddingTop: '19px',
            paddingBottom: '19px',
        },

        // Variant
        outlinedPrimary: {
            border: `1px solid ${theme.palette.primary.main}`,
            color: `${theme.palette.primary.main}`,
        },
        outlinedSecondary: {
            border: `1px solid ${theme.palette.common.textPrimary}`,
            color: `${theme.palette.common.textPrimary}`,
        },
        containedPrimary: {
            backgroundColor: `#000000`,
            color: `${theme.palette.common.textPrimary}`,
        },
        containedSecondary: {
            backgroundColor: theme.palette.common.bgSection,
            color: `${theme.palette.common.textPrimary}`,

            '&:hover': {
                backgroundColor: theme.palette.common.bgSection,
            },
        },
        textInfo: {
            backgroundColor: 'transparent',
            color: '#0F62FE',

            '.MuiSvgIcon-root': {
                color: '#0F62FE',
            },
        },

        // Icon
        startIcon: {
            marginBottom: 3,
        },
        endIcon: {
            // marginBottom: 3,
        },
    }
})

export default useStyles
