import { UserAddressResponse, UserResponseType } from '@/utils/api/auth'
import { useTranslation } from 'next-i18next'

export const userNameBuilder = (userFullname: UserResponseType['full_name']): string => {
    if (userFullname.length <= 8) return userFullname

    const splitedName = userFullname.split(' ')

    const getLastIndex = splitedName[splitedName.length - 1]

    return getLastIndex.length < 6 ? getLastIndex : getLastIndex.slice(0, 6) + '...'
}

export const userAddressBuilder = (userAddress: UserAddressResponse): string => {
    /* eslint-disable */
    const { t } = useTranslation('user')
    const { address_detail, city, district, ward } = userAddress

    const cityStr = t('city') + ' ' + city.name
    const districtStr = district.name
    const wardStr = ward.name

    return address_detail + ', ' + wardStr + ', ' + districtStr + ', ' + cityStr
}

export const parseUserGender = (genderNum: GenderNumber) => {
    const gender = { 0: 'female', 1: 'male', 2: 'other' }
    return gender[genderNum]
}
