import 'react-toastify/dist/ReactToastify.css'

import { Portal } from '@mui/material'
import { ToastContainer, ToastContainerProps } from 'react-toastify'
import useStyles from './AppToastify.styles'

const AppToastify = (props: ToastContainerProps) => {
    const { ...rest } = props
    const { classes, cx } = useStyles()

    return (
        <Portal>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                pauseOnHover
                limit={3}
                {...rest}
                className={(ctx) => cx(ctx?.defaultClassName, classes.root)}
                toastClassName={classes.toast}
                progressClassName={classes.progressBar}
            />
        </Portal>
    )
}

export default AppToastify
