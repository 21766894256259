const appColors = {
    textPrimary: '#F4F4F4',
    primary: '#161616',

    secondary: '#8D8D8D',
    iconSecondary: '#525252',

    bgSection: '#414142',

    borderActive: '#161616',
    borderDisable: '#C6C6C6',

    bgActive: '#0043CE',

    iconSecondaryColor: '#D1D1D1',

    textError: '#DA1E28',

    success: '#198038',
    error: '#DA1E28',

    white: '#fff',
} as { [key: string]: React.CSSProperties['color'] }

export default appColors
