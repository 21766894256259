export const SUPPORT_TYPE = {
    warranty: 'CHINH_SACH_BAO_HANH',
    delivery: 'CHINH_SACH_VAN_CHUYEN',
    privacy: 'QUYEN_RIENG_TU',
    frequentlyAskedQuestions: 'questions',
    // policy
    policyPrivacy: 'PRIVACY_POLICY_USER_INFO',
    policyUserInfo: 'TERM_OF_USES_USER_INFO',
} as const

export const SUPPORT_MENU = [
    {
        type: SUPPORT_TYPE.warranty,
        slug: '/support/warranty',
        translateKey: 'warranty',
    },
    {
        type: SUPPORT_TYPE.delivery,
        slug: '/support/delivery',
        translateKey: 'delivery',
    },
    {
        type: SUPPORT_TYPE.privacy,
        slug: '/support/privacy',
        translateKey: 'privacy',
    },
    {
        type: SUPPORT_TYPE.frequentlyAskedQuestions,
        slug: '/support/frequently-asked-questions',
        translateKey: 'frequentlyAskedQuestions',
    },
]

type SupportTypeKey = keyof typeof SUPPORT_TYPE
export type SupportTypeValue = (typeof SUPPORT_TYPE)[SupportTypeKey]
