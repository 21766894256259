export const GENERAL_INTRODUCTION = 'GENERAL_INTRODUCTION'

export const DETAIL_SPECIFICATIONS = 'DETAIL_SPECIFICATIONS'

export const COMPATIBLE = 'COMPATIBLE'

export const PACKAGE = 'PACKAGE'

export const SUPPORT = 'SUPPORT'

export const BACKGROUND_COLOR_SERIES = {
    // These base on Series code (id)
    '1': 'rgba(243, 119, 133, 0.08)', // M Series
    '2': 'rgba(255, 216, 108, 0.08)', // D Series
    '3': 'rgba(75, 194, 193, 0.08)', // E Series
    '4': 'rgba(107, 176, 213, 0.08)', // F Series
} as Record<string, React.CSSProperties['backgroundColor']>

export const PRODUCT_REQUEST_ERROR_TRANSLATE_KEY: { [key: string]: string } = {
    product_detail_not_inventory: 'error.inventory',
}
