import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CartState } from './types'
import { cartReducerAction } from './action'
import { CreateTempCartPayload, tempCartApi } from '@/utils/api/cart'
import { AppState } from '..'

const initialState: CartState = {
    cart: null,
    totalItems: 0,
    fetchingCart: false,

    cartProductSelected: [],

    cartCheckout: null,
}

export const createTempCartThunk = createAsyncThunk('cart/createTempCart', async (params: CreateTempCartPayload) => {
    const { data } = await tempCartApi.createTempCart(params)
    return data
})

export const getTempCartThunk = createAsyncThunk('cart/getTempCart', async () => {
    const { data } = await tempCartApi.getTempCart()
    return data
})

const cartStore = createSlice({
    name: 'cart',
    initialState,
    reducers: cartReducerAction,
    extraReducers(builder) {
        // Create temp cart
        builder.addCase(createTempCartThunk.pending, (state) => {
            state.fetchingCart = true
        })
        builder.addCase(createTempCartThunk.rejected, (state) => {
            state.fetchingCart = false
        })
        // Get temp cart
        builder.addCase(getTempCartThunk.pending, (state) => {
            state.fetchingCart = true
        })
        builder.addCase(getTempCartThunk.fulfilled, (state, action) => {
            state.cart = action.payload.params.map((cartItem) => ({
                ...cartItem,
                isEdit: false,
                loading: false,
            }))
            state.totalItems = state?.cart?.length || 0
            state.fetchingCart = false
        })
    },
})

export const selectCart = (state: AppState) => state.cart.cart
export const selectTotalItemsCart = (state: AppState) => state.cart.totalItems
export const selectFetchCartStatus = (state: AppState) => state.cart.fetchingCart

export const selectCartProductSelected = (state: AppState) => state.cart.cartProductSelected

export const selectCartCheckout = (state: AppState) => state.cart.cartCheckout

export const {
    changeStatusLoadingCartItem,
    changeStatusEditCartItem,
    removeCart,
    cancelAllEditCartItem,
    updateCartItemQuantity,

    updateCartProductSelected,

    updateCartCheckout,
} = cartStore.actions
export const cartReducer = cartStore.reducer
