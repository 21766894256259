import { commonAxios } from '@/libs'
import {
    AboutUsResponse,
    City,
    CommonRoute,
    ContactsResponse,
    DeliveryMethod,
    District,
    DistrictsPayload,
    FrequentlyAskedQuestionsPayload,
    FrequentlyAskedQuestionsResponse,
    PaymentMethod,
    Staffs,
    SupportPrivacyPayload,
    SupportPrivacyResponse,
    UserFeedbackPayload,
    UserSubcribePayload,
    VideoBannerResponse,
    Ward,
    WardsPayload,
} from './common.api.types'
import { AxiosResponseData } from '@/libs/axios'

export const commonApi = {
    userSubcribe: (payload: UserSubcribePayload) => {
        return commonAxios.post<AxiosResponseData>(CommonRoute.USER_SUBCRIBE_ROUTE, payload.params)
    },
    userFeedback: (payload: UserFeedbackPayload) => {
        return commonAxios.post<AxiosResponseData>(CommonRoute.USER_FEEDBACK_ROUTE, payload.params)
    },

    getSupportContent: (payload: SupportPrivacyPayload) => {
        return commonAxios.get<AxiosResponseData<SupportPrivacyResponse>>(CommonRoute.SUPPORT_PRIVACY_ROUTE, {
            params: payload.params,
        })
    },

    getFrequentlyAskedQuestions: (payload: FrequentlyAskedQuestionsPayload) => {
        return commonAxios.get<AxiosResponseData<FrequentlyAskedQuestionsResponse>>(
            CommonRoute.FREQUENTLY_ASKED_QUESTIONS_ROUTE,
            {
                params: payload.params,
            }
        )
    },

    getVideoBanner: () => {
        return commonAxios.get<AxiosResponseData<VideoBannerResponse[]>>(CommonRoute.VIDEO_BANNER_ROUTE)
    },

    getAboutUsContent: () => {
        return commonAxios.get<AxiosResponseData<AboutUsResponse[]>>(CommonRoute.ABOUT_US_ROUTE)
    },
    getContacts: () => {
        return commonAxios.get<AxiosResponseData<ContactsResponse>>(CommonRoute.CONTACTS_ROUTE)
    },
    getStaffs: () => {
        return commonAxios.get<AxiosResponseData<Staffs[]>>(CommonRoute.STAFFS_ROUTE)
    },

    getCities: () => {
        return commonAxios.get<AxiosResponseData<City[]>>(CommonRoute.CITIES_ROUTE)
    },
    getDistricts: (payload: DistrictsPayload) => {
        return commonAxios.get<AxiosResponseData<District[]>>(CommonRoute.DISTRICTS_ROUTE, {
            params: payload.params,
        })
    },
    getWards: (payload: WardsPayload) => {
        return commonAxios.get<AxiosResponseData<Ward[]>>(CommonRoute.WARDS_ROUTE, {
            params: payload.params,
        })
    },

    getDeliveryMethod: () => {
        return commonAxios.get<AxiosResponseData<DeliveryMethod[]>>(CommonRoute.DELIVERY_METHOD_ROUTE)
    },
    getPaymentMethod: () => {
        return commonAxios.get<AxiosResponseData<PaymentMethod[]>>(CommonRoute.PAYMENT_METHOD_ROUTER)
    },
}
