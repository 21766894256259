import { authConstants } from '@/utils/constants'
import { CancelToken, RawAxiosRequestHeaders } from 'axios'
import { City, District, Ward } from '../common'

export enum AuthApiRoute {
    SIGN_IN_ROUTE = '/members/login.json',
    SIGN_IN_SOCIAL_ROUTE = '/members/loginSocial.json',

    SIGN_UP_ROUTE = '/members/register.json',

    CHECK_PHONE_ROUTE = '/members/checkPhoneIsValid.json',
    CHECK_EMAIL_ROUTE = '/members/sendEmailVerifications.json',

    CHECK_VALID_CURRENT_PASSWORD = 'members/checkPassword.json',
    FORGET_PASSWORD_ROUTE = '/members/forgotPassword.json',
    CHECK_CODE_OTP_ROUTE = '/members/verificationCode.json',

    FETCH_USER_ROUTE = '/members/getProfile.json',
    EDIT_USER_PERSONAL_ROUTE = '/members/updateProfile.json',

    CHANGE_PASSWORD_ROUTE = '/members/changePassword.json',

    GET_USER_ADDRESS_ROUTE = '/memberAddresses/getList.json',
    CREATE_USER_ADDRESS_ROUTE = '/memberAddresses/createMemberAddress.json',
    EDIT_USER_ADDRESS_ROUTE = '/memberAddresses/updateById.json',
    SET_DEFAULT_USER_ADDRESS_ROUTE = '/memberAddresses/updateIsDefaultById.json',
    DELETE_USER_ADDRESS_ROUTE = '/memberAddresses/deleteById.json',

    USER_PURCHASED_ROUTE = '/orders/getListOrderByFilter.json',
}

type PayloadType = {
    headers?: RawAxiosRequestHeaders
    cancelToken?: CancelToken
}

export type CheckEmailPayloadType = {
    params: {
        email: string
        verification_type: typeof authConstants.AUTH_API_CHECK_EMAIL_REGISTER_TYPE_ID
    }
} & PayloadType

export type SignInPayloadType = {
    params: {
        username: string
        password: string
    }
} & PayloadType

export type SignInGooglePayloadType = {
    params: {
        code: string
    }
} & PayloadType

export type SignInFacebookPayloadType = {
    params: {
        access_token: string
    }
} & PayloadType

export type RegisterPayloadType = {
    params: {
        full_name: string
        phone_number: string
        email: string
        password: string
        code: string
        type: typeof authConstants.AUTH_API_REGISTER_TYPE_ID
    }
} & PayloadType

export type CheckEmailForgetPasswordType = {
    params: {
        email: string
        verification_type: typeof authConstants.AUTH_API_CHECK_EMAIL_FORGET_PASSWORD_TYPE_ID
    }
} & PayloadType

export type ForgetPasswordPayloadType = {
    params: {
        email: string
        password: string
        code: string
        verification_type: typeof authConstants.AUTH_API_CHECK_EMAIL_FORGET_PASSWORD_TYPE_ID
    }
} & PayloadType

export type ChangePasswordPayload = {
    data: {
        code: string
        password: string
        password_old: string
    }
} & PayloadType

export type CreateUserAddressPayload = {
    params: {
        name_receiver: string
        phone_receiver: string
        city_id: City['id']
        district_id: District['id']
        ward_id: Ward['id']
        building_detail?: string
        address_detail: string
        remarks?: string
        is_default?: BooleanNumber
    }
} & PayloadType

export type EditUserAddressPayload = {
    params: {
        member_address_id: SafeNumber
        name_receiver: string
        phone_receiver: string
        city_id: City['id']
        district_id: District['id']
        ward_id: Ward['id']
        building_detail?: string
        address_detail: string
        remarks?: string
        is_default?: BooleanNumber
    }
} & PayloadType

export type DeleteUserAddressPayload = {
    params: {
        member_address_id: UserAddressResponse['id']
    }
} & PayloadType

export type EditUserPersonalPayload = {
    data: {
        full_name?: string
        birthday?: string
        gender?: GenderNumber
        phone_number?: string
        avatars?: File
        code: string
    }
} & PayloadType

export type UserPurchasedPayload = {
    params: {
        page: number
        limit: number
        status: number
        search: string
    }
} & PayloadType

/**
 * Responses type
 */
export type UserResponseType = {
    id: number
    full_name: string
    email: string

    phone_number: string | null
    gender: GenderNumber
    birthday: string | null
    facebook_id: string | number | null
    google_id: string | number | null
    path: string | null

    is_first_use_promotion: boolean | null
} & PayloadType

export type SignInGoogleResponse = {
    token: string
} & Omit<UserResponseType, 'facebook_id' | 'google_id'>

export type UserAddressResponse = {
    id: SafeNumber
    name_receiver: string
    phone_receiver: string
    building_detail: string
    address_detail: string
    remarks: string
    is_default: BooleanNumber
    city: City
    district: District
    ward: Ward
}

export type UserPurchasedResponse = {
    total: number
    items: UserPurchased[]
}

export type UserPurchased = {
    id: SafeNumber
    status: number
    order_no: string
    shipping_fee: number
    total_price: number
    reason_cancel: string | null
    member_carts: UserPurchasedCart[]
}

export type UserPurchasedCart = {
    product_detail_id: SafeNumber
    quantity: number
    price: string | number
    product_name: string
    seri_name: string
    layout_name: string
    color_name: string
    switch_name: string
    path: string
}
