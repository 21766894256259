/** @type import("next").NextConfig */
module.exports = {
    i18n: {
        locales: ['vi-VN', 'en-US'],
        defaultLocale: 'vi-VN',
        localeDetection: false,
    },
    fallbackLng: {
        default: ['vi-vn', 'vi'],
        'en-US': ['en-us', 'en'],
    },
    localePath: typeof window === 'undefined' ? require('path').resolve('./public/locales') : '/locales',
    reloadOnPrerender: process.env.NODE_ENV === 'development',
}
