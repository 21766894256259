import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({
    name: 'AppToastify',
})((theme) => {
    const { fontSize, fontWeight, fontFamily, lineHeight } = theme.typography.bodyMed14

    return {
        toast: {
            fontSize,
            fontWeight,
            fontFamily,
            lineHeight,
        },
        progressBar: {
            '&.Toastify__progress-bar--success': {
                background: '#07bc0c',
            },
            '&.Toastify__progress-bar--error': {
                background: '#e74c3c',
            },
        },
    }
})

export default useStyles
