import axios from 'axios'
import nextI18NextConfig from '@@/next-i18next.config'

import { commonConfig } from '@/utils/configs'
import { toast } from 'react-toastify'

const commonAxios = axios.create({
    baseURL: commonConfig.API_BASE_URL,
})

commonAxios.interceptors.request.use(
    (req) => {
        if (!req.headers['Language']) {
            const lang = typeof window !== 'undefined' ? window.NextPublic.lang.replace('-', '_') : nextI18NextConfig.i18n!.defaultLocale.replace('-', '_')
            req.headers['Language'] = lang
        }

        switch ((req.method as string).toUpperCase()) {
            case 'GET': {
                req.params = req.params || {}
                // Object.assign(req.params, {});
                break
            }
            case 'POST': {
                // if (!(req.data instanceof FormData) && !!req.data) {
                //   req.data = commonHelpers.formatFormData(req.data);
                // }

                // if (req.data instanceof FormData) {
                // } else {
                //   req.data = req.data || {};
                //   // Object.assign(req.params, {});
                // }
                break
            }
            case 'PUT': {
                // if (!(req.data instanceof FormData) && !!req.data) {
                //   req.data = commonHelpers.formatFormData(req.data);
                // }
                // if (req.data instanceof FormData) {
                //   // req.data.append("language", window.NextPublic.lang);
                // } else {
                //   req.data = req.data || {};
                //   // Object.assign(req.params, {});
                // }
                break
            }
        }
        return req
    },
    (err) => {
        console.log(err)
        return Promise.reject(err)
    }
)

commonAxios.interceptors.response.use(
    (res) => {
        return res
    },
    (err) => {
        // console.log(err)
        // toast.error(err)
        // return Promise.reject(err)
        return err.response
    }
)

export default commonAxios
