import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AuthStateType } from './types'
import { authApi } from '@/utils/api'
import { SignInFacebookPayloadType, SignInGooglePayloadType, SignInPayloadType } from '@/utils/api/auth'
import { authReducerAction } from './action'
import { jwtService } from '@/services/jwt'
import { AppState } from '..'

export const initialState: AuthStateType = {
    user: null,
    fetchingUser: false,
}

export const signInThunk = createAsyncThunk('auth/signInThunk', async (params: SignInPayloadType) => {
    const { data } = await authApi.signIn(params)
    return data
})

export const signInWithGoogleThunk = createAsyncThunk(
    'auth/signInWithGoogleThunk',
    async (params: SignInGooglePayloadType) => {
        const { data } = await authApi.signInWithGoogle(params)
        return data
    }
)

export const signInWithFacebookThunk = createAsyncThunk(
    'auth/signInWithFacebookThunk',
    async (params: SignInFacebookPayloadType) => {
        const { data } = await authApi.signInWithFacebook(params)
        return data
    }
)

export const fetchUser = createAsyncThunk('auth/fetchUserThunk', async () => {
    const { data } = await authApi.fetchUser()
    return data
})

const authStore = createSlice({
    name: 'auth',
    initialState,
    reducers: authReducerAction,
    extraReducers(builder) {
        builder.addCase(signInThunk.fulfilled, (_, { payload }) => {
            jwtService.saveToken(payload?.params.token || '')
        })
        builder.addCase(signInWithGoogleThunk.fulfilled, (_, { payload }) => {
            jwtService.saveToken(payload?.params.token || '')
        })
        //
        builder.addCase(fetchUser.pending, (state) => {
            state.fetchingUser = true
        })
        builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
            state.fetchingUser = false
            state.user = payload.params
        })
    },
})

export const selectFetchUserStatus = (state: AppState) => state.auth?.fetchingUser
export const selectAuthUser = (state: AppState) => state.auth?.user

export const { signOut } = authStore.actions
export const authReducer = authStore.reducer
