import { FieldErrors, FormState } from 'react-hook-form'
import { useTranslation } from 'next-i18next'

type ErrorComboReturn = {
    error: boolean
    helperText: string
}

export const errorCombo = <T extends Record<string, any>>(
    errorsBag: FieldErrors<T>,
    fieldName: keyof T
): ErrorComboReturn => {
    /* eslint-disable */
    const { t } = useTranslation('form')

    let isError = false,
        errorMsg = ''
    isError = !!errorsBag[fieldName]

    // Error Message
    if (errorsBag[fieldName]) {
        if (typeof errorsBag[fieldName]?.message === 'string') errorMsg = errorsBag[fieldName]?.message as string
        else {
            // @ts-ignore
            const { label, key: errorKey, values } = errorsBag[fieldName]?.message
            const translatedLabel = t(label)
            const error = t(errorKey, { ...values })
            errorMsg = translatedLabel + ' ' + error
        }
    }

    return { error: isError, helperText: errorMsg }
}

export const shouldDisableSubmit = <T extends Record<string, any>>(formState: FormState<T>) => {
    const { isSubmitting, isSubmitted, isValid } = formState
    return isSubmitting || (isSubmitted && !isValid)
}
